import { Booking } from '@wix/ambassador-bookings-server/types';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  CalendarViewModel,
  createCalendarViewModel,
  createDummyCalendarViewModel,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CatalogData, Service } from '@wix/bookings-uou-types';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';
import { CalendarApi } from '../../api/CalendarApi';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import { BottomSectionStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { TimePickerStatus } from './ViewModel/timePickerViewModel/timePickerViewModel';
import { CalendarErrors, TriggeredByOptions } from '../../utils/bi/consts';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import { FilterTypes } from './ViewModel/filterViewModel/filterViewModel';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { FlowElements } from './Hooks/useFlow';
import {
  createEmptyStateViewModel,
  CatalogErrorType,
} from './ViewModel/emptyStateViewModel/emptyStateViewModel';
import { FilterOptions, Optional } from '../../types/types';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  bottomSectionStatus: BottomSectionStatus;
  timePickerStatus: TimePickerStatus;
  selectedService: Service;
  selectedDate?: string;
  selectedTimezone?: string;
  selectedMonth?: string;
  dateAvailability?: any;
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
};

// For more info about controller structure,
// check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  let rerender: () => Promise<void> = async () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        reportError,
        translations: { t },
      } = flowAPI;
      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const calendarApi = new CalendarApi({ wixSdkAdapter, reportError });
      const onCatalogError = (type: CatalogErrorType) => {
        const emptyStateViewModel = createEmptyStateViewModel({
          t,
          type,
        });
        controllerConfig.setProps({ emptyStateViewModel });
      };

      const catalogDate: Optional<CatalogData> = await calendarApi.getCatalogData(
        onCatalogError,
      );
      if (!catalogDate) {
        return;
      }

      const {
        services: [service],
        businessInfo,
        activeFeatures,
        staffMembers,
      } = catalogDate;

      const initialState: CalendarState = createInitialState({
        service,
        staffMembers,
        wixSdkAdapter,
      });

      const calendarContext: CalendarContext = createCalendarContext({
        flowAPI,
        businessInfo,
        activeFeatures,
        calendarApi,
        wixSdkAdapter,
        initialState,
      });

      const { onStateChange, render } = await createControlledComponent<
        CalendarState,
        CalendarActions,
        CalendarViewModel,
        CalendarContext
      >({
        controllerConfig,
        initialState,
        viewModelFactory: wixSdkAdapter.isEditorMode()
          ? createDummyCalendarViewModel
          : createCalendarViewModel,
        actionsFactory: createCalendarActions,
        context: calendarContext,
      });
      rerender = render;

      const { biLogger } = calendarContext;

      biLogger.bookingsCalendarFiltersLoad({
        triggeredBy: TriggeredByOptions.INITIALIZE_WIDGET,
        selectedFilters: JSON.stringify(initialState.filterOptions),
      });
      if (!wixSdkAdapter.isSSR()) {
        onStateChange((state) => {
          biLogger.update(state);
        });
      }
    },
    updateConfig() {
      rerender();
    },
  };
};

export default createController;
