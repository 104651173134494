import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import settingsParams from '../../settingsParams';
import { CalendarErrors } from '../../../../utils/bi/consts';
import { FilterOptions, Optional } from '../../../../types/types';
import * as _ from 'lodash';

export type NoAvailableSlotsViewModel = {
  noSlotsText: string;
  goToNextAvailableLinkText?: string;
  noAvailableSlotMessage?: string;
};

export function createNoAvailableSlotsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): NoAvailableSlotsViewModel {
  const { settings, t } = context;
  const { calendarErrors, filterOptions } = state;

  const noSlotsText = settings.get(settingsParams.timePickerNoSlotsText);

  const isNoNextAvailableDate = calendarErrors.some(
    (error) => error === CalendarErrors.NO_NEXT_AVAILABLE_DATE,
  );
  const isNextAvailableDateServerError = calendarErrors.some(
    (error) => error === CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR,
  );

  const showNextAvailableLink =
    !isNoNextAvailableDate && !isNextAvailableDateServerError;
  const goToNextAvailableLinkText = showNextAvailableLink
    ? settings.get(settingsParams.timePickerGoToNextAvailableLinkText)
    : undefined;

  const noAvailableSlotMessage = getNoAvailableSlotMessage({
    isNoNextAvailableDate,
    filterOptions,
    t,
  });

  return {
    noSlotsText,
    goToNextAvailableLinkText,
    noAvailableSlotMessage,
  };
}

function getNoAvailableSlotMessage({
  isNoNextAvailableDate,
  filterOptions,
  t,
}: {
  isNoNextAvailableDate: boolean;
  filterOptions: FilterOptions;
  t: TFunction;
}): Optional<string> {
  if (isNoNextAvailableDate) {
    const areFilterExists = _.every(filterOptions, 'length');
    return areFilterExists
      ? t(
          'app.time-picker.notifications.no-next-available-date-matching-filters',
        )
      : t('app.time-picker.notifications.no-next-available-date');
  }
}
